// extracted by mini-css-extract-plugin
export var acceptPrompt = "index-module--acceptPrompt--3KmIG";
export var acceptText = "index-module--acceptText--jvNDT";
export var blogLink = "index-module--blogLink--eryAO";
export var blogTitle = "index-module--blogTitle--sn1Vh";
export var calculatorFirstLine = "index-module--calculatorFirstLine--cAJrx";
export var calculatorIllustration = "index-module--calculatorIllustration--zyMyo";
export var calculatorImg = "index-module--calculatorImg--TGD4i";
export var calculatorText = "index-module--calculatorText--XT11b";
export var calculatorTextWrapper = "index-module--calculatorTextWrapper--uAsyG";
export var calculatorWrapper = "index-module--calculatorWrapper--qLVnK";
export var cardFour = "index-module--cardFour--QsXaF";
export var cardIconImg = "index-module--cardIconImg--uplRk";
export var cardOne = "index-module--cardOne--SSBx1";
export var cardThree = "index-module--cardThree--6a1ba";
export var cardTwo = "index-module--cardTwo--8C7wp";
export var characterSvg = "index-module--characterSvg--YLxz3";
export var compareCard = "index-module--compareCard--S9uR8";
export var compareCardIcon = "index-module--compareCardIcon--veLyb";
export var compareCardText = "index-module--compareCardText--dr3SF";
export var compareDetails = "index-module--compareDetails--fReCu";
export var compareItemCaption = "index-module--compareItemCaption--t8T+O index-module--sectionDetails--tImTm";
export var compareItemName = "index-module--compareItemName--V-JEi index-module--title--FsR+z";
export var compareItemsWrapper = "index-module--compareItemsWrapper--ZW1N5";
export var compareTextWrapper = "index-module--compareTextWrapper--2G7As";
export var compareTitle = "index-module--compareTitle--xthBc";
export var compareWrapper = "index-module--compareWrapper--GFEz9";
export var ctaButton = "index-module--ctaButton--43YML";
export var ctaButtonDisabled = "index-module--ctaButtonDisabled--x6frg";
export var ctaText = "index-module--ctaText--LO5pF";
export var emailInput = "index-module--emailInput--8n+ja";
export var emailInputWrapper = "index-module--emailInputWrapper--OYqUx";
export var hero = "index-module--hero--RXViO";
export var heroButtons = "index-module--heroButtons--ZRP+r";
export var heroContent = "index-module--heroContent--O1NpU";
export var heroFirstLine = "index-module--heroFirstLine--TEQbo";
export var heroIllustration = "index-module--heroIllustration--541y-";
export var heroParagraph = "index-module--heroParagraph--TWQcM";
export var heroText = "index-module--heroText--dARcD";
export var linkToBlog = "index-module--linkToBlog--0Z68W";
export var newsletter = "index-module--newsletter--eRjgA";
export var newsletterCTA = "index-module--newsletterCTA--IXFII";
export var newsletterCTAAction = "index-module--newsletterCTAAction--3E4nB";
export var newsletterCTAText = "index-module--newsletterCTAText--gVtIq";
export var newsletterCTATitle = "index-module--newsletterCTATitle--CitHN";
export var newsletterCTAWrapper = "index-module--newsletterCTAWrapper--3vhmV";
export var oasisCalculator = "index-module--oasisCalculator--4QW6q";
export var oasisCompare = "index-module--oasisCompare--lqFpj";
export var openModal = "index-module--openModal--Vjs9k";
export var paraText = "index-module--paraText--CDEnj";
export var postsWrapper = "index-module--postsWrapper--3JEaA";
export var privacyCheck = "index-module--privacyCheck--beIe4";
export var purpleBtn = "index-module--purpleBtn--NMqPg";
export var recentPosts = "index-module--recentPosts--ySdMF";
export var recentPostsMain = "index-module--recentPostsMain--56LTL";
export var recentPostsTitle = "index-module--recentPostsTitle--VB9Bj";
export var sectionDetails = "index-module--sectionDetails--tImTm";
export var sectionHeader = "index-module--sectionHeader--koSVx";
export var subscribeArrow = "index-module--subscribeArrow--TUjPL";
export var subscribeButton = "index-module--subscribeButton--jEtUV";
export var textWrapper = "index-module--textWrapper--MCs5n";
export var title = "index-module--title--FsR+z";
export var titleAccent = "index-module--titleAccent--BAuwT index-module--title--FsR+z";
export var titleAccentBlack = "index-module--titleAccentBlack--LTmyH index-module--titleAccent--BAuwT index-module--title--FsR+z";
export var titleBold = "index-module--titleBold--72yYJ index-module--title--FsR+z";
export var titleSemiBold = "index-module--titleSemiBold--m1eum index-module--title--FsR+z";
export var titleThin = "index-module--titleThin--23Nxe index-module--title--FsR+z";
export var transparentBtn = "index-module--transparentBtn--VAG+v index-module--purpleBtn--NMqPg";