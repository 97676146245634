// extracted by mini-css-extract-plugin
export var article = "post-link-module--article--8ZJsb";
export var articleBlogHome = "post-link-module--articleBlogHome--n2Kga";
export var articleHome = "post-link-module--articleHome--Q9+06";
export var articleHomeLink = "post-link-module--articleHomeLink--W3+G8";
export var cbpCategory = "post-link-module--cbpCategory--SLg5Z";
export var cbpExcerptText = "post-link-module--cbpExcerptText--zEBlY";
export var cbpImageColumn = "post-link-module--cbpImageColumn--v1l-w";
export var cbpImageDesktop = "post-link-module--cbpImageDesktop--EsoB4";
export var cbpImageMobile = "post-link-module--cbpImageMobile--k8zH4";
export var cbpPostInfo = "post-link-module--cbpPostInfo--H3-eX";
export var cbpPostTitle = "post-link-module--cbpPostTitle--lgVv2";
export var cbpTextColumn = "post-link-module--cbpTextColumn--T8xR4";
export var cbpTextWrapper = "post-link-module--cbpTextWrapper--3ZTmz";
export var centralBlogPost = "post-link-module--centralBlogPost--nG-QO";
export var data = "post-link-module--data--miDsO";
export var dataText = "post-link-module--dataText--iSgIE";
export var doubleCardBlogPost = "post-link-module--doubleCardBlogPost--p6Prg";
export var doubleCardCategory = "post-link-module--doubleCardCategory--9ODju";
export var doubleCardExcerptText = "post-link-module--doubleCardExcerptText--6OsOM";
export var doubleCardImage = "post-link-module--doubleCardImage--8DyfA";
export var doubleCardImageColumn = "post-link-module--doubleCardImageColumn--BV8bD";
export var doubleCardInlinePostLink = "post-link-module--doubleCardInlinePostLink--lyFF1";
export var doubleCardPostInfo = "post-link-module--doubleCardPostInfo--CuxF0";
export var doubleCardPostTitle = "post-link-module--doubleCardPostTitle--PFmKW";
export var doubleCardReadingTime = "post-link-module--doubleCardReadingTime--ngRZV";
export var doubleCardTextColumn = "post-link-module--doubleCardTextColumn--iypYN";
export var doubleCardTextWrapper = "post-link-module--doubleCardTextWrapper--CluU7";
export var excerptText = "post-link-module--excerptText--CUeo9";
export var image = "post-link-module--image--MJrDw";
export var imageHome = "post-link-module--imageHome--S0BQc";
export var imageTall = "post-link-module--imageTall--xnw2F";
export var inlinePostLink = "post-link-module--inlinePostLink--6kRF6";
export var linkInfoHome = "post-link-module--linkInfoHome--OihQT";
export var linkInfoTall = "post-link-module--linkInfoTall--a0+mx";
export var postLink = "post-link-module--postLink--1m13P";
export var postLinkImage = "post-link-module--postLinkImage--nC4tU";
export var postLinkImageHome = "post-link-module--postLinkImageHome--GEh1J";
export var postLinkImageTall = "post-link-module--postLinkImageTall--ZWZ1U";
export var postLinkInfo = "post-link-module--postLinkInfo--sm9sm";
export var postLinkLight = "post-link-module--postLinkLight--0XZ9m post-link-module--postLink--1m13P";
export var tall = "post-link-module--tall--bWKV4";
export var titleText = "post-link-module--titleText--Bge0R";
export var titleTextHome = "post-link-module--titleTextHome--CNbPI";
export var titleTextTall = "post-link-module--titleTextTall--Ojxjf";